import React from 'react'
import './Playzoneau.css'; 
import atFlg from '../images/atFlg.png'
import back2 from '../images/back2.jpg'
import back3 from '../images/back3.jpg'
import back4 from '../images/back4.jpg'
import back5 from '../images/back5.jpg'
import background from '../images/back7.jpg'
import { Helmet } from 'react-helmet-async';
import favicon from './../images/deFlg.png'

function HotelDe() {
  return (
    <div>
    <Helmet>
        <title>Reisebüro in Deutschland</title>
        <meta name="description" content="Reisebüro in Deutschland" />
        <link rel="icon" type="image/x-icon" href={favicon} />
    </Helmet>
    <nav class="navbar navbar-expand-lg sticky-top bg-white">
        <div class="container">
            <a class="navbar-brand" href="#"><img src={favicon} class="top-logo" alt="logo.com"/> <span style={{whiteSpace:'normal'}}>Die besten Spielautomatenstandorte in Deutschland
            </span> </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Navigation umschalten">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#home">Startseite</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#list">Casino-Hotels</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#contact-us">Kontaktieren Sie uns</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <section id="home" class="px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform" style={{background:`url(${background})`}}>
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-white">
                    <h3 class="fw-bold mb-3 Playfair-font hero-title" style={{color:"white"}}>Beste Casino-Hotels in Deutschland besuchen</h3>
                    <p>Träumen Sie von einem Kurzurlaub, der den Reiz luxuriöser Unterkünfte mit der Begeisterung des Casinospieles kombiniert? Suchen Sie nicht weiter - Deutschland bietet eine Auswahl an herausragenden Casino-Hotels, die einen unvergesslichen Ausflug versprechen. Ob Sie nach einer Dachoase oder einer luxuriösen Suite mit atemberaubender Aussicht suchen, diese sorgfältig ausgewählten Ziele bieten die perfekte Mischung aus Opulenz, Unterhaltung und der Möglichkeit, Ihr Glück an den Tischen zu versuchen.</p>
                </div>
            </div>
        </div>
    </section>

    <section id="list" class="hotel section-padding padding-top-80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-howtouse text-left pb-5">
                        <div class="container-body">
                            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back2} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Austria Classic Casino Hotel Wien</a></h5>
                                            <p class="card-text">Für diejenigen, die sowohl die Ruhe als auch die Aufregung des Spiels gleichermaßen schätzen, bietet Hotel Wien & Casino die perfekte Balance. Entspannen Sie sich in minimalistischen, aber stilvollen Zimmern, lassen Sie Ihren Geist klären, bevor Sie sich in eine Welt des Spielerlebnisses stürzen. Von 9 bis 5 Uhr erleben Sie den Nervenkitzel von 370 Spielautomaten und über 20 Tischspielen, die für endlose Unterhaltung während Ihres Aufenthalts sorgen. Dennoch ist das Hotel Wien mehr als ein Spielerparadies - es ist eine Oase der romantischen Gelassenheit. Eingehüllt in eine zenartige Atmosphäre ist es ein idyllischer Ort für einen intimen Rückzug. Freuen Sie sich auf erholsame kulinarische Erlebnisse und genießen Sie die harmonische Atmosphäre, die jeden Moment begleitet. Betrachten Sie das Hotel Wien für Hochzeiten und romantische Veranstaltungen, denn es ist eine bezaubernde Kulisse, die kostbare Erinnerungen fördert.</p>
                                            <a href="#" class="list-btn">Jetzt buchen</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Sehr gut</h4>
                                                    <p>100% verifizierte Bewertungen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back4} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Casino Hotel Kaiserhof Wien</a></h5>
                                            <p class="card-text">Auf der Suche nach einem königlichen Rückzugsort? Das Star Gold Coast ist die Antwort. Genießen Sie luxuriöse Suiten, die Opulenz neu definieren. Jedes Zimmer ist ein Refugium des raffinierten Komforts. Aber der wahre Reiz liegt jenseits der Tür Ihrer Suite. Tauchen Sie ein in eine Welt kulinarischer Genüsse in den acht Restaurants und Bars und kosten Sie köstliche Kreationen, die Ihre Geschmacksnerven kitzeln. Priorisieren Sie die Entspannung im Azure Spa, wo ultimative Verwöhnung auf Sie wartet, bevor Sie sich in die faszinierende Casino-Arena wagen. Mit 70 Tischspielen und erstaunlichen 1400 Spielautomaten ist der Nervenkitzel des Spiels nur einen Herzschlag entfernt. Nehmen Sie an laufenden Turnieren teil oder ziehen Sie sich in die exklusive Sovereign VIP-Spielelounge zurück, um sicherzustellen, dass jeder Moment unvergesslich wird. Der moderne Enthusiast wird die Vielzahl elektronischer Spielautomaten und Tischspiele zu schätzen wissen, die rund um die Uhr verfügbar sind.</p>
                                            <a href="#" class="list-btn">Jetzt buchen</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Sehr gut</h4>
                                                    <p>100% verifizierte Bewertungen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back3} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Casino Hotel Alpenhof</a></h5>
                                            <p class="card-text">Genießen Sie die Essenz von Raffinesse im Alpenhof Hotel Casino. Stellen Sie sich vor, wie Sie in einem Rooftop-Pool entspannen, umgeben von schwingenden Palmen, während Sie den atemberaubenden Blick auf das Riff genießen. Ihre Unterkünfte gehen über das Gewöhnliche hinaus und bieten Zimmer mit Whirlpool-Bädern und privaten Balkonen mit Panoramablick. Tauchen Sie ein in eine Welt exquisiter Speisemöglichkeiten, von handgefertigten Cocktails bis hin zu Fine-Dining-Erlebnissen, die alle die Bühne für Ihr Spielerlebnis bereiten. Betreten Sie das Reich des Casinos, wo über 500 Spielautomaten neben einer vielfältigen Auswahl an Spielen wie Roulette, Baccarat und Blackjack locken. Die Aufregung geht rund um die Uhr weiter und ermöglicht es Ihnen, von 10 bis 2 Uhr in das Geschehen einzutauchen. Selbst wenn Sie kleine Kinder dabei haben, keine Sorge - eine kostenlose Babysitterin steht Ihnen zur Verfügung.</p>
                                            <a href="#" class="list-btn">Jetzt buchen</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Sehr gut</h4>
                                                    <p>100% verifizierte Bewertungen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back5} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Boutique Casino Hotel Donauwalzer</a></h5>
                                            <p class="card-text">Boutique Hotel Donauwalzer lädt mit einer Vielzahl von Auswahlmöglichkeiten ein, egal ob Sie sich nach Stadtblick oder Flusspanoramen sehnen. Genießen Sie Luxus in prächtigen Villen mit weitläufigen Fenstern, die atemberaubende Ausblicke einfangen. Wenn Sie nach bezahlbarem Luxus ohne Kompromisse beim Stil suchen, werden Sie sicherlich von den durchdacht gestalteten Zimmern begeistert sein. Schlemmen Sie von einem reichen Angebot an kulinarischen Köstlichkeiten, von verschiedenen Küchen bis hin zu Spezialgetränken, während die Bar eine Atmosphäre verbreitet, die an klassische Filme erinnert. Bereiten Sie sich darauf vor, von der schieren Größe der weitläufigen Casino-Etage im Boutique Hotel Donauwalzer begeistert zu sein, die sich über 220.000 Quadratfuß erstreckt. Navigieren Sie durch königliche Tischspiele und Hunderte von Spielautomaten oder nutzen Sie geplante Aktionen, die Ihr Spielerlebnis aufwerten. Selbst für neugierige Beobachter ist die Einrichtung ein Anblick, eine Erkundung des Unterhaltungsangebots, das das Gewöhnliche übertrifft. Ihr außergewöhnlicher Casino-Hotelurlaub erwartet Sie - eine Fusion von Luxus, Spannung und Entspannung, die verspricht, Ihr Reiseerlebnis neu zu definieren. Buchen Sie noch heute Ihre Auszeit und erleben Sie die Anziehungskraft der besten Casino-Hotels in Deutschland.</p>
                                            <a href="#" class="list-btn">Jetzt buchen</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Sehr gut</h4>
                                                    <p>100% verifizierte Bewertungen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="contact-us" class="hotel section-padding bg-light">
        <div class="container">
            <div class="row align-items-center">
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <iframe width="100%" height="560" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1805.6610080055732!2d9.985718175676237!3d53.548684059566085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18f1b2431c221%3A0x9191285816e897b8!2sGro%C3%9Fer%20Burstah%2C%2020457%20Hamburg%2C%20Almaniya!5e1!3m2!1saz!2saz!4v1733226393881!5m2!1saz!2saz" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer class="bg-light p-2 text-center">
        <div class="container padding-20">
            <div class="social-icon">
                <a href="#" class="fa fa-facebook"></a>
                <a href="#" class="fa fa-instagram"></a>
            </div>
            <a href='#' style={{color:"#717171", textDecoration:"none",margin:"10px 0",display:"block"}}>Datenschutzrichtlinie</a>
            <p class="text-dark footer-text">© 2023 Copyright LuckyBoomAt
.com Alle Rechte vorbehalten.</p>
        </div>
    </footer>
</div>

  )
}

export default HotelDe

